class JobOffers {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.localizationButton = this.root.querySelectorAll(
            '[data-job-offers-localization]'
        );
        this.gridEl = this.root.querySelector('[data-job-offers-grid]');

        this.html = this.gridEl.innerHTML;
        this.currPage = 1;
        this.currLocalization = null;
        this.currBranch = null;

        return true;
    }

    init() {
        this.setLocalizationButtons();
    }

    setLocalizationButtons() {
        if (!this.localizationButton) return;

        this.onLocalization = this.onLocalizationClick.bind(this);
        this.localizationButton.forEach(item =>
            item.addEventListener('click', this.onLocalization)
        );
    }

    async onLocalizationClick(event) {
        const { currentTarget } = event;
        const id = parseInt(
            currentTarget.getAttribute('data-job-offers-localization')
        );

        this.currLocalization = id;
        this.currPage = 1;
        this.html = '';

        this.resetLocalizations();
        this.setActiveItem(currentTarget);

        await this.loadOffers();
    }

    async loadOffers() {
        const formData = new FormData();
        this.gridEl.classList.add('is-loading')
        if (this.currLocalization)
            formData.append('localization', this.currLocalization);

        formData.append('no_offer', this.gridEl.getAttribute('data-no-offer'));

        const request = await fetch(`${home_url}/wp-json/job-offers/v1/list`, {
            method: 'POST',
            body: formData,
        });

        const res = await request.json();
        const { html, max_pages } = res;

        this.gridEl.innerHTML = html;
        if (res) {
            this.gridEl.classList.remove('is-loading')
        }
    }

    resetLocalizations() {
        if (!this.localizationButton) return;
        this.localizationButton.forEach(item =>
            item.classList.remove('-active')
        );
    }

    setActiveItem(item) {
        if (!item) return;
        item.classList.add('-active');
    }
}

function initJobOffers() {
    const sections = document.querySelectorAll('[data-job-offers]');
    if (!sections) return;

    sections.forEach(item => new JobOffers(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initJobOffers();
} else {
    document.addEventListener('DOMContentLoaded', initJobOffers);
}
